<template>
	<w-dialog
		v-if="value"
		:closable="false"
		max-width="500px"
		:title="value.isFolder ? $t('documents.duplicate.folder_title') : $t('documents.duplicate.document_title')"
		:value="dialog"
	>
		<w-layout column>
			<w-flex v-text="dialogText" />
			<w-flex>
				<w-checkbox v-model="applyToAllConflicts" :label="$t('documents.duplicate.apply_to_all_conflicts')" />
			</w-flex>
		</w-layout>
		<template v-slot:actions>
			<w-spacer />
			<w-btn flat @click="doCancel()">{{ $t('actions.cancel') }}</w-btn>
			<w-btn flat @click="doCreate()">{{ $t('actions.create') }}</w-btn>
			<w-btn flat @click="doMerge()">{{ value.isFolder ? $t('actions.merge') : $t('documents.replace_document_newversion') }}</w-btn>
		</template>
	</w-dialog>
</template>

<script>
export default {
	name: 'ShouldMergeDialog',
	props: {
		value: {
			type: [File, Object],
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			applyToAllConflicts: false,
			dialog: false
		}
	},
	computed: {
		dialogText: function () {
			let result = ''

			const nodeName = this.value?.name
			if (this.value?.isFolder) {
				result = this.$t('documents.duplicate.folder_description', { name: nodeName })
			} else {
				result = this.$t('documents.duplicate.document_description', { name: nodeName })
			}

			return result
		}
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		doCancel: function () {
			this.$emit('close')
			this.$emit('remove')
		},
		doCreate: function () {
			this.$emit('input', {
				merge: false,
				recursive: this.applyToAllConflicts
			})
			this.$emit('remove')
		},
		doMerge: function () {
			this.$emit('input', {
				merge: true,
				recursive: this.applyToAllConflicts
			})
			this.$emit('remove')
		}
	}
}
</script>
